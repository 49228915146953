function Contact(){

    return(
    <>
        {/* <div className="contact">Contact</div> */}
        <p>Send me an email at inquiry[at]throsby[.]dev</p>
    </>
    )
}

export default Contact;